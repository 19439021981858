import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import theme from "../../helpers/theme";
import { getToken } from "../../services/authentication";
import { useTranslation } from "react-i18next";
import { getTvLimit } from "../../helpers/common";
import { getActiveCompany } from "../../services/localStorage";
import { motion } from "framer-motion";

const API_URL = process.env.REACT_APP_API_URL;

const UploadPhotosComponent: React.FC = () => {
  const [imageTitle, setImageTitle] = useState("");
  const [qrCodeLink, setQRCodeLink] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTv, setSelectedTv] = useState<number>(1);
  const [tvCount, setTvCount] = useState<number>(0);
  const [tvIds, setTvIds] = useState<number[]>([]);

  const { t } = useTranslation();

  const formClear = () => {
    setImageTitle("");
    setImageFile(null);
    setImagePreview(null);
  };

  useEffect(() => {
    const fetchTvs = async () => {
      const company = getActiveCompany();
      if (company) {
        const customLimits = company.customLimits;
        const subscriptionPlanId = company.subscriptionPlanId;
        const limit = getTvLimit(subscriptionPlanId, customLimits);
        setTvCount(limit);

        const tvs = company.tvs || [];
        // Sort TV IDs to ensure they are in correct order
        const sortedTvs = tvs.sort((a, b) => a.id - b.id);
        setTvIds(sortedTvs.map((tv) => tv.id));
      }
    };

    fetchTvs();
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleUpload = async () => {
    if (!imageFile) {
      alert("Please fill in all fields and select an image.");
      return;
    }
    setLoading(true);
    const companyId = getActiveCompany()?.id;
    if (!companyId) {
      setSnackbarMessage("No active company found!");
      setSnackbarOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append("companyId", companyId.toString());
    formData.append("title", imageTitle);
    formData.append("qrCodeLink", qrCodeLink);
    formData.append("tvId", tvIds[selectedTv - 1].toString());
    formData.append("media", imageFile);

    try {
      const token = getToken();
      const response = await axios.post(
        API_URL + "/company/uploadMedia",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const firstUploadResult = response.data.data[0]; // Get the first upload result

      if (firstUploadResult && firstUploadResult.Location) {
        setSnackbarMessage("Image uploaded successfully!");
        setUploadSuccess(true);
      } else {
        setSnackbarMessage("Error processing the upload");
        setUploadSuccess(false);
      }

      setLoading(false);
      formClear();
      setSnackbarOpen(true);
    } catch (error: any) {
      let errorMessage = "Error uploading video";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      setSnackbarMessage(errorMessage);
      setUploadSuccess(false);
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => { }}
      >
        <CircularProgress size={64} color="inherit" />
      </Backdrop>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={3} justifyContent="center">
          {/* Left Column - Upload Image Section */}
          <Grid item xs={12} md={5}>
            <Card 
              variant="outlined" 
              sx={{ 
                height: '100%',
                backgroundColor: 'background.default',
                borderColor: 'rgba(255, 255, 255, 0.12)',
                borderRadius: '12px',
                overflow: 'hidden',
                maxWidth: '450px',
                mx: 'auto'
              }}
            >
              <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ color: "white", mb: 2, fontWeight: 'bold' }}>
                  {t("uploadPhotosComponent_uploadImage")}
                </Typography>
                
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <label htmlFor="upload-image-input">
                    <input
                      accept="image/*"
                      id="upload-image-input"
                      type="file"
                      hidden
                      onChange={handleImageChange}
                    />
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: window.innerWidth <= 600 ? "85%" : "90%",
                        margin: "0 auto",
                        borderRadius: '8px',
                        overflow: "hidden",
                        border: imagePreview ? 'none' : '2px dashed rgba(255, 255, 255, 0.3)',
                        padding: imagePreview ? 0 : window.innerWidth <= 600 ? "16px 16px 40px" : "16px 16px 32px",
                        minHeight: window.innerWidth <= 600 ? '150px' : '220px',
                        backgroundColor: imagePreview ? 'transparent' : 'rgba(255, 255, 255, 0.05)',
                        marginBottom: '16px'
                      }}
                    >
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Thumbnail"
                          style={{ 
                            width: "100%", 
                            height: "auto", 
                            maxHeight: window.innerWidth <= 600 ? '150px' : '220px',
                            objectFit: 'contain',
                            borderRadius: '8px'
                          }}
                        />
                      ) : (
                        <Box sx={{ textAlign: 'center' }}>
                          <img
                            src="/assets/images/card1/uploadImg.png"
                            alt="Upload"
                            style={{
                              width: "auto",
                              height: window.innerWidth <= 600 ? "100px" : "140px",
                              marginBottom: window.innerWidth <= 600 ? '8px' : '12px'
                            }}
                          />
                        </Box>
                      )}
                    </motion.div>
                  </label>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Right Column - Settings Section */}
          <Grid item xs={12} md={5}>
            <Card 
              variant="outlined" 
              sx={{ 
                height: '100%',
                backgroundColor: 'background.default',
                borderColor: 'rgba(255, 255, 255, 0.12)',
                borderRadius: '12px',
                maxWidth: '450px',
                mx: 'auto'
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ color: "white", mb: 3, fontWeight: 'bold' }}>
                  {t("uploadPhotosComponent_settings")}
                </Typography>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.disabled", mb: 1 }}
                  >
                    {t("uploadPhotosComponent_photoTitle")}
                  </Typography>
                  <TextField
                    placeholder={t("uploadPhotosComponent_enterTitle")}
                    fullWidth
                    variant="outlined"
                    value={imageTitle}
                    onChange={(e) => setImageTitle(e.target.value)}
                    sx={{
                      backgroundColor: "#3D3D3D",
                      "& .MuiOutlinedInput-input": {
                        color: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary.main",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary.main",
                        },
                      },
                    }}
                  />
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.disabled", mb: 1 }}
                  >
                    {t("uploadPhotosComponent_qrCodeLink")}
                  </Typography>
                  <TextField
                    placeholder={t("uploadPhotosComponent_enterQRCode")}
                    fullWidth
                    variant="outlined"
                    value={qrCodeLink}
                    onChange={(e) => setQRCodeLink(e.target.value)}
                    sx={{
                      backgroundColor: "#3D3D3D",
                      "& .MuiOutlinedInput-input": {
                        color: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary.main",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary.main",
                        },
                      },
                    }}
                  />
                </Box>
                
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.disabled", mb: 1 }}
                  >
                    {t("uploadPhotosComponent_selectTv")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, flexWrap: 'wrap' }}>
                    {Array.from({ length: tvCount }, (_, index) => (
                      <motion.div
                        key={index}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Button
                          variant={
                            selectedTv === index + 1 ? "contained" : "outlined"
                          }
                          color="primary"
                          onClick={() => setSelectedTv(index + 1)}
                          sx={{
                            minWidth: '40px',
                            height: '40px',
                            backgroundColor:
                              selectedTv === index + 1
                                ? theme.palette.primary.main
                                : "#3D3D3D",
                            borderColor: "white",
                            color: "white",
                            "&:hover": {
                              backgroundColor:
                                selectedTv === index + 1
                                  ? theme.palette.primary.dark
                                  : "#5a5a5a",
                            },
                          }}
                        >
                          {index + 1}
                        </Button>
                      </motion.div>
                    ))}
                  </Box>
                </Box>
                
                {/* Upload Button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    style={{ width: '80%', maxWidth: '300px', overflow: 'hidden' }}
                  >
                    <Button
                      onClick={handleUpload}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ 
                        mt: 2, 
                        height: "50px", 
                        borderRadius: "8px",
                        fontWeight: 'bold',
                        boxShadow: '0 4px 10px rgba(254, 134, 57, 0.3)'
                      }}
                    >
                      {t("uploadPhotosComponent_upload")}
                    </Button>
                  </motion.div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </motion.div>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={uploadSuccess ? "success" : "error"}
          sx={{
            backgroundColor: "black",
            color: "primary.main",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UploadPhotosComponent;

import React, { useState, useEffect } from "react";
import { Container, Typography, TextField, Box, Button, Select, MenuItem, Chip, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { getToken, saveAdminData } from "../services/authentication";
import { useTranslation } from "react-i18next";
import { countryRegions } from "../constants/regions";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../models/backend";
import theme from "../helpers/theme";

const API_URL = process.env.REACT_APP_API_URL;

interface Company {
  id: number;
  name: string;
  active: boolean;
  approved: boolean;
  subscriptionPlanId: number | null;
  regionId: number;
  customLimits: number[];
  ownerId: number;
}

interface TvStatus {
  id: number;
  description: string;
  companyId: number;
  companyName: string;
  status: string;
  lastSeen: string;
  ipAddress: string;
  splitScreenOption: number;
}

const AdminAllCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [allTvs, setAllTvs] = useState<TvStatus[]>([]);
  const [selectedCompanyTvs, setSelectedCompanyTvs] = useState<TvStatus[]>([]);
  const [tvDialogOpen, setTvDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = getToken();
        const response = await axios.get<Company[]>(`${API_URL}/company/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("All companies", response.data);
        setCompanies(response.data);
        setFilteredCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    const fetchAllTvs = async () => {
      try {
        const token = getToken();
        const response = await axios.get<TvStatus[]>(`${API_URL}/tv/getAllOnlineTvs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllTvs(response.data);
      } catch (error) {
        console.error("Error fetching TV status:", error);
      }
    };

    fetchCompanies();
    fetchAllTvs();
  }, []);

  const handleImpersonate = async (userId: number) => {
    try {
      const token = getToken();
      saveAdminData();

      const responseRaw = await axios.post(`${API_URL}/users/impersonate`, { targetUserId: userId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const response: LoginResponse = responseRaw.data;
      localStorage.setItem("wistaa_data", JSON.stringify(response));

      navigate('/');
    } catch (error) {
      console.error("Impersonation failed:", error);
    }
  };

  // Handle search
  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filtered = companies.filter((company) =>
      company.name.toLowerCase().includes(query)
    );
    setFilteredCompanies(filtered);
  }, [searchQuery, companies]);

  // Map regionId to region name based on company.regionId and country
  const getRegionTitle = (regionId: number, country: string) => {
    const regions = countryRegions[country] || [];
    const region = regions.find((region) => region.id === regionId);
    return region ? region.title : "Unknown Region";
  };

  // Function to open TV dialog
  const openTvDialog = (companyId: number, companyName: string) => {
    const companyTvs = allTvs.filter(tv => tv.companyId === companyId);
    // Sort TVs by ID
    companyTvs.sort((a, b) => a.id - b.id);
    setSelectedCompanyTvs(companyTvs);
    setSelectedCompanyName(companyName);
    setTvDialogOpen(true);
  };

  // Format date for display
  const formatDate = (dateString: string) => {
    if (!dateString) return "Never";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Function to handle subscription plan change
  const changeCompanySubscriptionModel = async (companyId: number, subscriptionPlanId: number) => {
    try {
      const token = getToken();
      await axios.put(
        `${API_URL}/subscription/updateCompanySubscription`,
        { companyId, subscriptionPlanId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the state with the new subscription plan
      setCompanies((prevCompanies) =>
        prevCompanies.map((company) =>
          company.id === companyId ? { ...company, subscriptionPlanId } : company
        )
      );

      // Show success toast
      setSnackbarMessage("Subscription plan updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating subscription plan:", error);

      // Show error toast
      setSnackbarMessage("Failed to update subscription plan.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Function to check if a TV is online for a company
  const isTVOnline = (companyId: number) => {
    return allTvs.some(tv => tv.companyId === companyId && tv.status === 'online');
  };

  // Define columns for DataGrid with original names
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 0.7 },
    // {
    //   field: "active",
    //   headerName: "Active",
    //   flex: 1,
    //   renderCell: (params) => (params.value ? "Yes" : "No"),
    // },
    // {
    //   field: "approved",
    //   headerName: "Approved",
    //   flex: 1,
    //   renderCell: (params) => (params.value ? "Yes" : "No"),
    // },
    { field: "id", headerName: "Company Id", flex: 0.4 },
    {
      field: "tvs",
      headerName: "TVs",
      flex: 0.4,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => openTvDialog(params.row.id, params.row.name)}
        >
          View TVs
        </Button>
      ),
    },
    {
      field: "subscriptionPlanId",
      headerName: "Subscription Plan",
      flex: 0.5,
      renderCell: (params) => (
        <Select
          value={params.value || ""}
          onChange={(e) =>
            changeCompanySubscriptionModel(params.row.id, e.target.value as number)
          }
          fullWidth
          sx={{ color: "white" }}
        >
          <MenuItem value={1}>Plan 1</MenuItem>
          <MenuItem value={2}>Plan 2</MenuItem>
          <MenuItem value={3}>Plan 3</MenuItem>
          <MenuItem value={4}>Plan 4</MenuItem>
        </Select>
      ),
    },
    {
      field: "regionId",
      headerName: "Region Id",
      flex: 0.6,
      renderCell: (params) => getRegionTitle(params.value, 'Slovenia'),
    },
    // {
    //   field: "customLimits",
    //   headerName: "Custom Limits",
    //   flex: 1,
    //   renderCell: (params) => params.value.join(", "),
    // },
    {
      field: "isActive",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => (
        <Chip
          label={isTVOnline(params.row.id) ? "Active" : "Inactive"}
          color={isTVOnline(params.row.id) ? "success" : "error"}
        />
      ),
    },
    {
      field: "impersonate",
      headerName: "Impersonate",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleImpersonate(params.row.ownerId)}
        >
          Impersonate
        </Button>
      ),
      flex: 1,
    },
  ];

  return (
    <Container component="main" sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h4" sx={{ color: "white", mb: 3 }}>
        {t("adminAllCompanies_title")}
      </Typography>

      {/* Search Field */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label={t("adminAllCompanies_search")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
          }}
        />
      </Box>

      {/* Data Grid */}
      <Box sx={{ height: 600, width: "100%", background: "black" }}>
        <DataGrid
          rows={filteredCompanies}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .MuiDataGrid-root": {
              color: "white",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#333",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "#444",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid #555",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "white",
            },
          }}
        />
      </Box>
      
      {/* TV Details Dialog */}
      <Dialog
        open={tvDialogOpen}
        onClose={() => setTvDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "#222",
            color: "white",
            margin: { xs: '16px', sm: '32px' },
            width: { xs: 'calc(100% - 32px)', sm: 'auto' }
          }
        }}
      >
        <DialogTitle sx={{ color: "white" }}>
          TVs for {selectedCompanyName}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ overflowX: 'auto' }}>
            <TableContainer component={Paper} sx={{ backgroundColor: "#333" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>ID</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", display: { xs: 'none', sm: 'table-cell' } }}>Description</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", display: { xs: 'none', md: 'table-cell' } }}>IP Address</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Last Seen</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", display: { xs: 'none', sm: 'table-cell' } }}>Split</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCompanyTvs.length > 0 ? (
                    selectedCompanyTvs.map((tv) => (
                      <TableRow 
                        key={tv.id}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)'
                          }
                        }}
                        onClick={() => {
                          // On mobile, clicking a row could show more details in the future
                        }}
                      >
                        <TableCell sx={{ color: "white" }}>{tv.id}</TableCell>
                        <TableCell sx={{ color: "white", display: { xs: 'none', sm: 'table-cell' } }}>{tv.description}</TableCell>
                        <TableCell>
                          <Chip
                            label={tv.status === 'online' ? "Online" : "Offline"}
                            color={tv.status === 'online' ? "success" : "error"}
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ color: "white", display: { xs: 'none', md: 'table-cell' } }}>{tv.ipAddress || "N/A"}</TableCell>
                        <TableCell sx={{ color: "white", whiteSpace: 'nowrap' }}>{formatDate(tv.lastSeen)}</TableCell>
                        <TableCell sx={{ color: "white", display: { xs: 'none', sm: 'table-cell' } }}>{tv.splitScreenOption}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ color: "white" }}>
                        No TVs found for this company
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          
          {/* Mobile-friendly list view visible only on extra small screens */}
          <Box sx={{ mt: 2, display: { xs: 'block', sm: 'none' } }}>
            {selectedCompanyTvs.length > 0 ? (
              selectedCompanyTvs.map((tv) => (
                <Paper 
                  key={tv.id} 
                  sx={{ 
                    p: 2, 
                    mb: 2, 
                    backgroundColor: '#333',
                    borderLeft: tv.status === 'online' ? '4px solid green' : '4px solid red'
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    TV #{tv.id} - {tv.status === 'online' ? "Online" : "Offline"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Description:</strong> {tv.description}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Last Seen:</strong> {formatDate(tv.lastSeen)}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>IP:</strong> {tv.ipAddress || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Split Screen:</strong> {tv.splitScreenOption}
                  </Typography>
                </Paper>
              ))
            ) : (
              <Typography align="center">No TVs found for this company</Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTvDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{
            backgroundColor: "black",
            color: "primary.main",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminAllCompanies;
import React from 'react';
import UploadPhotosComponent from './UploadPhotosComponent';
import GalleryComponent from './GalleryComponent';
import StatisticsComponent from './StatisticsComponent';
import { User } from '../../models/backend';
import UploadVideosComponent from './UploadVideosComponent';
import RequestAd from './RequestAd';
import SocialMediaComponent from './SocialMediaComponent';
import { motion, AnimatePresence } from 'framer-motion';

interface DynamicExpandableSectionProps {
    activeComponent: string;
    user: User | null
}

const DynamicExpandableSection: React.FC<DynamicExpandableSectionProps> = ({ activeComponent, user }) => {
    const renderComponent = () => {
        switch (activeComponent) {
            case 'UploadPhotos':
                return <UploadPhotosComponent/>;
            case 'UploadVideos':
                return <UploadVideosComponent/>;
            case 'Gallery':
                return <GalleryComponent />
            case 'Statistics':
                return <StatisticsComponent />
            case 'RequestImageVideo':
                return <RequestAd user ={user} />
            case 'SocialMedia':
                return <SocialMediaComponent/>
            default:
                return null;
        }
    };

    return (
        <AnimatePresence mode="wait">
            {activeComponent && (
                <motion.div
                    key={activeComponent}
                    initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
                    animate={{ 
                        opacity: 1, 
                        height: 'auto',
                        transition: { 
                            height: { duration: 0.4 },
                            opacity: { duration: 0.3, delay: 0.1 }
                        }
                    }}
                    exit={{ 
                        opacity: 0, 
                        height: 0,
                        transition: { 
                            height: { duration: 0.3 },
                            opacity: { duration: 0.2 }
                        }
                    }}
                >
                    {renderComponent()}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default DynamicExpandableSection;

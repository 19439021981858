import React from 'react';
import { 
  Dialog, 
  DialogContent, 
  Typography, 
  Button, 
  IconButton, 
  useMediaQuery,
  Box,
  Paper,
  Avatar,
  Slide,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { User } from '../models/backend';
import { logout, stopImpersonating, isImpersonating } from '../services/authentication';
import { useNavigate } from 'react-router-dom';
import SubscriptionCarousel from './SubscriptionCard';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { TransitionProps } from '@mui/material/transitions';

// Slide transition for dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface UserProfileDialogProps {
  open: boolean;
  user: User | null;
  onClose: () => void;
}

const UserProfileDialog: React.FC<UserProfileDialogProps> = ({ open, user, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleLogoutOrStopImpersonating = () => {
    if (isImpersonating()) {
      stopImpersonating();
      window.location.reload();
    } else {
      logout();
      navigate('/login');
    }
  };

  if (!user) return null;

  return (
    <Dialog
      onClose={handleClose}
      open={open && !!user}
      fullWidth={true}
      maxWidth={isMobile ? 'xs' : 'sm'}
      fullScreen={isMobile}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          border: isMobile ? 'none' : 'thick solid',
          borderColor: 'primary.main',
          borderRadius: isMobile ? '0px' : '16px',
          backgroundColor: 'black',
          overflow: 'hidden',
          m: isMobile ? 0 : 2,
          height: isMobile ? '100%' : 'auto',
          maxHeight: isMobile ? '100%' : '80vh'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: isMobile ? 1.5 : 2,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          sx={{ 
            color: 'white', 
            fontWeight: 'bold',
            fontSize: isMobile ? '1.1rem' : '1.25rem'
          }}
        >
          {t('userProfileDialog_profile') || 'Profile'}
        </Typography>
        <IconButton 
          edge="end" 
          color="inherit" 
          onClick={handleClose} 
          aria-label="close"
          size="small"
          sx={{ 
            color: 'white',
            padding: 0,
            width: '32px',
            height: '32px',
            minWidth: '32px',
            minHeight: '32px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <DialogContent sx={{ 
        p: 0, 
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '4px'
        }
      }}>
        <Paper 
          elevation={0} 
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.05)', 
            m: isMobile ? 1 : 2, 
            p: isMobile ? 1.5 : 2, 
            borderRadius: '12px',
            transition: 'all 0.3s ease'
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 1,
            flexDirection: isMobile ? 'column' : 'row',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <Avatar 
              sx={{ 
                bgcolor: 'primary.main', 
                mr: isMobile ? 0 : 2, 
                mb: isMobile ? 1 : 0,
                width: isMobile ? 60 : 50, 
                height: isMobile ? 60 : 50, 
                fontSize: isMobile ? '1.5rem' : '1.25rem' 
              }}
            >
              {user.name?.charAt(0) || '?'}
            </Avatar>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start', mb: 0.5 }}>
                <AccountCircleIcon sx={{ color: 'primary.main', mr: 1, fontSize: isMobile ? '1rem' : '1.25rem' }} />
                <Typography 
                  variant={isMobile ? "body1" : "h6"} 
                  sx={{ 
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '1rem' : '1.1rem'
                  }}
                >
                  {user.name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                <EmailIcon sx={{ color: 'primary.main', mr: 1, fontSize: isMobile ? '0.9rem' : '1.1rem' }} />
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'grey.400',
                    fontSize: isMobile ? '0.8rem' : '0.9rem'
                  }}
                >
                  {user.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper 
          elevation={0} 
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.05)', 
            m: isMobile ? 1 : 2, 
            p: isMobile ? 1.5 : 2, 
            borderRadius: '12px',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
            }
          }}
        >
          <Box 
            onClick={handleLogoutOrStopImpersonating}
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              cursor: 'pointer',
              py: 0.5
            }}
          >
            <ExitToAppIcon sx={{ 
              color: 'primary.main', 
              mr: 1.5,
              fontSize: isMobile ? '1.2rem' : '1.5rem'
            }} />
            <Typography 
              variant={isMobile ? "body2" : "body1"} 
              sx={{ 
                color: 'white',
                fontSize: isMobile ? '0.9rem' : '1rem'
              }}
            >
              {isImpersonating() ? "Stop Impersonating" : t('userProfileDialog_logout')}
            </Typography>
          </Box>
        </Paper>

        <Box sx={{ 
          m: isMobile ? 1 : 2, 
          mt: isMobile ? 2 : 3
        }}>
          <Typography 
            variant={isMobile ? "subtitle1" : "h6"} 
            sx={{ 
              color: 'white', 
              fontWeight: 'bold',
              fontSize: isMobile ? '1rem' : '1.1rem',
              mb: 1,
              px: isMobile ? 1 : 0
            }}
          >
            {t('userProfileDialog_subscriptions') || 'Subscription Plans'}
          </Typography>
          <SubscriptionCarousel user={user} />
        </Box>

        {isMobile && (
          <Box sx={{ 
            width: '100%', 
            p: 2, 
            mt: 2,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{
                width: 'auto',
                minWidth: '120px',
                maxWidth: '200px',
                py: 1,
                px: 3,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 'bold'
              }}
            >
              {t('userProfileDialog_close') || 'Close'}
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileDialog;

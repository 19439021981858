import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Typography, Paper, Snackbar, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { getToken } from '../../../services/authentication';
import { getActiveCompany } from '../../../services/localStorage';
import { MediaItem } from '../../../models/media';
import { useTranslation } from 'react-i18next';
import ScheduleDialog from './ScheduleDialog';
import { motion } from 'framer-motion';

const API_URL = process.env.REACT_APP_API_URL;

export interface MediaComponentProps {
    data: MediaItem[];
    refreshMedia: () => void;
    tvId: number;
}

const MediaTabComponent: React.FC<MediaComponentProps> = ({ data, refreshMedia, tvId }) => {
    const [open, setOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | undefined>();
    const [isLocal, setIsLocal] = useState<boolean>(false);
    const [isGlobal, setIsGlobal] = useState<boolean>(false);
    const [deleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

    const { t } = useTranslation();

    const handleOpen = (media: MediaItem) => {
        setSelectedMedia(media);
        setOpen(true);
        if (media.ad) {
            setIsLocal(media.ad.usedAs.includes('local'));
            setIsGlobal(media.ad.usedAs.includes('global'));
        } else {
            setIsLocal(false);
            setIsGlobal(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (selectedMedia?.ad) {
            setIsLocal(selectedMedia.ad?.usedAs.includes('local')!);
            setIsGlobal(selectedMedia.ad?.usedAs.includes('global')!);
        }
    }, [selectedMedia]);

    const deleteMedia = async () => {
        try {
            const token: string = getToken();
            const mediaId = selectedMedia?.id;

            await axios.post(
                `${API_URL}/media/deleteMedia`,
                { mediaId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            setSnackbarMessage("Media deleted successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setIsDeleteDialogOpen(false);
            setOpen(false);
            refreshMedia();
        } catch (error: any) {
            console.error('Error deleting media:', error);
            setSnackbarMessage("Error deleting media: " + error.response.data.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const toggleMarkMediaAsAd = async (local: boolean, global: boolean, schedulingInfo?: any) => {
        try {
            const token: string = getToken();
            const company = getActiveCompany();
            if (!company) {
                setSnackbarMessage("No active company found!");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
            const companyId = company.id;
            const mediaId = selectedMedia?.id;
            const usedAs: string[] = [];
            if (local) usedAs.push('local');
            if (global) usedAs.push('global');

            const response = await axios.post(
                `${API_URL}/ads/markMediaAsAd`,
                { companyId, usedAs, mediaId, tvId, ...schedulingInfo },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );

            if (response.status === 200) {
                setIsLocal(local);
                setIsGlobal(global);
                setSnackbarMessage("Media marked as ad successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                refreshMedia();
            }
        } catch (error: any) {
            console.error('Error toggling media as ad:', error);
            setSnackbarMessage(error.response.data.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const renderMediaItem = (media: MediaItem, index: number) => {
        if (media.mediaType === 'image') {
            return (
                <motion.div
                    whileHover={{ scale: 1.03 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                >
                    <img
                        key={index}
                        src={media.mediaLink}
                        alt={`Not showing ${index}`}
                        style={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover',
                            cursor: 'pointer',
                            borderRadius: '8px'
                        }}
                        onClick={() => handleOpen(media)}
                    />
                </motion.div>
            );
        } else if (media.mediaType === 'video') {
            return (
                <motion.div 
                    whileHover={{ scale: 1.03 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ position: 'relative', cursor: 'pointer', width: '100%', height: '100%', overflow: 'hidden' }}
                    onClick={() => handleOpen(media)}
                >
                    <video
                        key={index}
                        src={media.mediaLink}
                        style={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover',
                            borderRadius: '8px'
                        }}
                        controls
                        onClick={(e) => e.stopPropagation()} // Stop the click event on the video element itself
                    />
                    {/* Play overlay */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            opacity: 0,
                            transition: 'opacity 0.3s ease',
                            borderRadius: '8px',
                            '&:hover': {
                                opacity: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255,255,255,0.8)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    width: 0,
                                    height: 0,
                                    borderTop: '10px solid transparent',
                                    borderBottom: '10px solid transparent',
                                    borderLeft: '15px solid #000',
                                    marginLeft: '5px'
                                }}
                            />
                        </Box>
                    </Box>
                </motion.div>
            );
        }
    };

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 24
            }
        }
    };

    const StatusIndicator = ({ active, label }: { active: boolean, label: string }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <motion.div
                initial={{ scale: 0.8, opacity: 0.5 }}
                animate={{ 
                    scale: active ? 1 : 0.8, 
                    opacity: active ? 1 : 0.5,
                    color: active ? '#4caf50' : '#9e9e9e'
                }}
                transition={{ duration: 0.3 }}
            >
                <CheckIcon 
                    sx={{ 
                        color: active ? '#4caf50' : '#9e9e9e',
                        transition: 'color 0.3s ease'
                    }} 
                />
            </motion.div>
            <Typography 
                variant="body2"
                sx={{ 
                    color: active ? '#ffffff' : 'rgba(255, 255, 255, 0.7)',
                    fontWeight: active ? 'bold' : 'normal',
                    transition: 'all 0.3s ease'
                }}
            >
                {label}
            </Typography>
        </Box>
    );

    return (
        <Box>
            <Typography 
                variant="subtitle2" 
                sx={{ 
                    color: 'grey', 
                    mb: 3, 
                    fontStyle: 'italic',
                    textAlign: 'center'
                }}
            >
                {t('galleryMediaTab_showingLastMedia')}
            </Typography>

            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <Grid container spacing={3}>
                    {data.map((media, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <motion.div variants={itemVariants}>
                                <Paper 
                                    elevation={3} 
                                    sx={{ 
                                        overflow: 'hidden', 
                                        borderRadius: '10px',
                                        height: '180px',
                                        backgroundColor: 'rgba(30, 30, 30, 0.7)',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            boxShadow: '0 8px 16px rgba(0,0,0,0.3)'
                                        }
                                    }}
                                >
                                    {renderMediaItem(media, index)}
                                </Paper>
                                <Box sx={{ 
                                    mt: 1, 
                                    display: 'flex', 
                                    justifyContent: 'space-between',
                                    px: 1
                                }}>
                                    <StatusIndicator 
                                        active={media.ad?.usedAs.includes('local') || false} 
                                        label={t('galleryMediaTab_local')}
                                    />
                                    <StatusIndicator 
                                        active={media.ad?.usedAs.includes('global') || false} 
                                        label={t('galleryMediaTab_global')}
                                    />
                                </Box>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </motion.div>

            <ScheduleDialog
                open={open}
                onClose={handleClose}
                media={selectedMedia}
                tvId={tvId}
                onToggleMarkMediaAsAd={toggleMarkMediaAsAd}
                onDelete={deleteMedia}
                isLocal={isLocal}
                isGlobal={isGlobal}
                deleteDialogOpen={deleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
            />

            <Modal
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <motion.div
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    transition={{ type: "spring", stiffness: 300, damping: 25 }}
                >
                    <Box sx={{
                        outline: 'none',
                        position: 'relative',
                        backgroundColor: 'background.default',
                        padding: 3,
                        width: '90%',
                        maxWidth: '400px',
                        borderRadius: '12px',
                        border: '4px solid',
                        borderColor: 'primary.main',
                        boxShadow: '0 10px 30px rgba(0,0,0,0.5)'
                    }}>
                        <Typography id="delete-modal-title" variant="h6" sx={{ textAlign: 'center', color: 'text.primary', mb: 2, fontWeight: 'bold' }}>
                            {t('galleryMediaTab_deleteAd')}
                        </Typography>
                        <Typography id="delete-modal-description" sx={{ mt: 2, color: 'text.primary', textAlign: 'center' }}>
                            {t('galleryMediaTab_confirmDelete')}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 4 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsDeleteDialogOpen(false)}
                                sx={{ minWidth: '120px', fontWeight: 'bold', boxShadow: 'none' }}
                            >
                                {t('galleryMediaTab_cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={deleteMedia}
                                sx={{ minWidth: '120px', fontWeight: 'bold', boxShadow: 'none' }}
                            >
                                {t('galleryMediaTab_delete')}
                            </Button>
                        </Box>
                    </Box>
                </motion.div>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbarSeverity} 
                    sx={{ 
                        width: '100%',
                        backgroundColor: snackbarSeverity === 'success' ? 'rgba(46, 125, 50, 0.9)' : 'rgba(211, 47, 47, 0.9)',
                        color: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.3)'
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MediaTabComponent;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './pages/Layout';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import ConnectPage from './pages/ConnectPage';
import ProtectedRoute from './routes/protectedRoute';
import "./App.css"
import AdminAdRequests from './pages/AdminAdRequests';
import NotFoundPage from './pages/NotFound';
import AdminRoute from './routes/adminRoute';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AdminAllCompanies from './pages/AdminAllCompanies';
import PaymentFinished from './pages/PaymentFinished';
import VerifyMailPage from './pages/VerifyMailPage';
import VerifyPromptPage from './pages/VerficationSuccessPage';
import AdminEditSubscriptions from './pages/AdminEditSubscriptions';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ad-requests',
          element: (
            <AdminRoute>
              <AdminAdRequests />
            </AdminRoute>
          ),
        },
        {
          path: 'all-companies',
          element: (
            <AdminRoute>
              <AdminAllCompanies />
            </AdminRoute>
          ),
        },
        {
          path: 'edit-subscriptions',
          element: (
            <AdminRoute>
              <AdminEditSubscriptions />
            </AdminRoute>
          ),
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/connect',
      element: <ConnectPage />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/payment-finished',
      element: <PaymentFinished />,
    },
    {
      path: '/reset-password/:token',
      element: <ResetPasswordPage />,
    },
    {
      path: '/verification/:token',
      element: <VerifyMailPage />,
    },
    {
      path: '/prompt-verify',
      element: <VerifyPromptPage />,
    },
    // Add other non-protected routes as needed
  ]);

  return <RouterProvider router={router} />;
}

export default App;

import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Modal, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MediaItem } from '../../../models/media';
import { motion, AnimatePresence } from 'framer-motion';

interface ScheduleDialogProps {
    open: boolean;
    onClose: () => void;
    media: MediaItem | undefined;
    tvId: number;
    onToggleMarkMediaAsAd: (isLocal: boolean, isGlobal: boolean, schedulingInfo?: any) => Promise<void> | void;
    onDelete: () => void;
    isLocal: boolean;
    isGlobal: boolean;
    deleteDialogOpen: boolean;
    setIsDeleteDialogOpen: (open: boolean) => void;
}

const ScheduleDialog: React.FC<ScheduleDialogProps> = ({
    open,
    onClose,
    media,
    tvId,
    onToggleMarkMediaAsAd,
    onDelete,
    isLocal,
    isGlobal,
    deleteDialogOpen,
    setIsDeleteDialogOpen }) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [recurrence, setRecurrence] = useState<string>('once');
    const [isScheduling, setIsScheduling] = useState<boolean>(false);
    const { t } = useTranslation();

    const recurrenceOptions = [
        { value: 'once', label: t('recurrenceOnce') },
        { value: 'daily', label: t('recurrenceDaily') },
        { value: 'weekly', label: t('recurrenceWeekly') },
        { value: 'monthly', label: t('recurrenceMonthly') },
    ];

    useEffect(() => {
        if (media?.ad) {
            if (media.ad.startDate || media.ad.endDate || media.ad.recurrenceType) {
                setIsScheduling(true);
                setStartDate(media.ad.startDate ? dayjs(media.ad.startDate) : null);
                setEndDate(media.ad.endDate ? dayjs(media.ad.endDate) : null);
                setRecurrence(media.ad.recurrenceType || 'once');
            }
        }
    }, [media]);

    const handleButtonClick = (local: boolean, global: boolean) => {
        const info = isScheduling
            ? {
                startDate: startDate ? startDate.toISOString() : null,
                endDate: endDate ? endDate.toISOString() : null,
                recurrenceType: recurrence,
                recurrenceEndDate: endDate ? endDate.toISOString() : null,
            }
            : {};
        onToggleMarkMediaAsAd(local, global, info);
    };

    const handleToggleScheduling = () => {
        setIsScheduling(!isScheduling);
        if (!isScheduling) {
            setStartDate(null);
            setEndDate(null);
            setRecurrence('once');
        }
    };

    const handleDeleteClick = async () => {
        if (isLocal || isGlobal) await onToggleMarkMediaAsAd(false, false);
        setIsDeleteDialogOpen(true);
    };

    const renderMediaPreview = () => {
        if (!media) return null;
        if (media.mediaType === 'image') {
            return (
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                    style={{ 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <img 
                        src={media.mediaLink} 
                        alt="Enlarged" 
                        style={{ 
                            maxWidth: '100%', 
                            maxHeight: '60vh', 
                            borderRadius: '8px',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                        }} 
                    />
                </motion.div>
            );
        }
        if (media.mediaType === 'video') {
            return (
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                    style={{ 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <video 
                        src={media.mediaLink} 
                        controls 
                        style={{ 
                            maxWidth: '100%', 
                            maxHeight: '60vh', 
                            borderRadius: '8px',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                        }} 
                    />
                </motion.div>
            );
        }
    };

    // Animation variants
    const scheduleVariants = {
        hidden: { opacity: 0, height: 0, overflow: 'hidden' },
        visible: { 
            opacity: 1, 
            height: 'auto',
            transition: { 
                duration: 0.5,
                ease: "easeInOut"
            }
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="media-modal-title"
                aria-describedby="media-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    style={{ width: '90%', maxWidth: '600px' }}
                >
                    <Box sx={{
                        outline: 'none',
                        position: 'relative',
                        backgroundColor: '#121212',
                        padding: 3,
                        width: '100%',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        borderRadius: '12px',
                        border: '4px solid',
                        borderColor: '#fe8639',
                        boxShadow: '0 10px 30px rgba(0,0,0,0.5)'
                    }}>
                        {renderMediaPreview()}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
                            <Button
                                variant="contained"
                                style={{ 
                                    backgroundColor: isScheduling ? '#4caf50' : '#9e9e9e',
                                    color: 'white'
                                }}
                                onClick={handleToggleScheduling}
                                sx={{ 
                                    py: 1.5,
                                    fontWeight: 'bold',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                                fullWidth
                            >
                                {isScheduling ? t('scheduleAd') + ' ✓' : t('scheduleAd')}
                            </Button>
                            <AnimatePresence>
                                {isScheduling && (
                                    <motion.div
                                        variants={scheduleVariants}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                    >
                                        <Typography 
                                            variant="h6" 
                                            sx={{ 
                                                textAlign: 'center', 
                                                color: '#ffffff',
                                                my: 2,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {t('scheduleAd')}
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box onClick={() => document.getElementById('startDatePicker')?.focus()} sx={{ width: '100%', mb: 2 }}>
                                                <DateTimePicker
                                                    label={t('startDate')}
                                                    value={startDate}
                                                    onChange={(newValue) => setStartDate(newValue)}
                                                    ampm={false}
                                                    slotProps={{
                                                        textField: {
                                                            id: 'startDatePicker',
                                                            fullWidth: true,
                                                            variant: 'outlined'
                                                        }
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        backgroundColor: '#3D3D3D',
                                                        '& .MuiOutlinedInput-input': {
                                                            color: 'white',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'white',
                                                            borderRadius: '8px'
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#fe8639',
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#fe8639',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box onClick={() => document.getElementById('endDatePicker')?.focus()} sx={{ width: '100%', mb: 2 }}>
                                                <DateTimePicker
                                                    label={t('endDate')}
                                                    value={endDate}
                                                    onChange={(newValue) => setEndDate(newValue)}
                                                    ampm={false}
                                                    slotProps={{
                                                        textField: {
                                                            id: 'endDatePicker',
                                                            fullWidth: true,
                                                            variant: 'outlined'
                                                        }
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        backgroundColor: '#3D3D3D',
                                                        '& .MuiOutlinedInput-input': {
                                                            color: 'white',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'white',
                                                            borderRadius: '8px'
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#fe8639',
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#fe8639',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                        <FormControl sx={{
                                            width: '100%',
                                            backgroundColor: '#3D3D3D',
                                            '& .MuiSelect-select': {
                                                color: 'white',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'white',
                                                borderRadius: '8px'
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fe8639',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fe8639',
                                            },
                                        }}>
                                            <InputLabel>{t('recurrence')}</InputLabel>
                                            <Select
                                                value={recurrence}
                                                onChange={(e) => setRecurrence(e.target.value)}
                                                label={t('recurrence')}
                                            >
                                                {recurrenceOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button
                                    variant="contained"
                                    style={{ 
                                        backgroundColor: isLocal ? '#4caf50' : '#9e9e9e',
                                        color: 'white'
                                    }}
                                    onClick={() => handleButtonClick(!isLocal, isGlobal)}
                                    sx={{ 
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        minWidth: '100px',
                                        transition: 'all 0.3s ease',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                >
                                    {t('galleryMediaTab_local')}
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ 
                                        backgroundColor: isGlobal ? (media?.ad?.isActive ? '#4caf50' : '#fe8639') : '#9e9e9e',
                                        color: 'white'
                                    }}
                                    onClick={() => handleButtonClick(isLocal, !isGlobal)}
                                    sx={{ 
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        minWidth: '100px',
                                        transition: 'all 0.3s ease',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                >
                                    {t('galleryMediaTab_global')}
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#f44336', color: 'white' }}
                                    onClick={handleDeleteClick}
                                    sx={{ 
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        transition: 'all 0.3s ease',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                >
                                    {t('galleryMediaTab_delete')}
                                </Button>
                                <IconButton 
                                    onClick={onClose} 
                                    style={{ backgroundColor: 'rgba(0,0,0,0.2)', color: '#f44336' }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(0,0,0,0.3)'
                                        }
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </motion.div>
            </Modal>

            <Modal
                open={deleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <motion.div
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    transition={{ type: "spring", stiffness: 300, damping: 25 }}
                >
                    <Box sx={{
                        outline: 'none',
                        position: 'relative',
                        backgroundColor: '#121212',
                        padding: 3,
                        width: '90%',
                        maxWidth: '400px',
                        borderRadius: '12px',
                        border: '4px solid',
                        borderColor: '#fe8639',
                        boxShadow: '0 10px 30px rgba(0,0,0,0.5)'
                    }}>
                        <Typography id="delete-modal-title" variant="h6" sx={{ textAlign: 'center', color: '#ffffff', mb: 2, fontWeight: 'bold' }}>
                            {t('galleryMediaTab_deleteAd')}
                        </Typography>
                        <Typography id="delete-modal-description" sx={{ mt: 2, color: '#ffffff', textAlign: 'center' }}>
                            {t('galleryMediaTab_confirmDelete')}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 4 }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#9e9e9e', color: 'white' }}
                                onClick={() => setIsDeleteDialogOpen(false)}
                                sx={{ 
                                    minWidth: '120px', 
                                    fontWeight: 'bold', 
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                {t('galleryMediaTab_cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#f44336', color: 'white' }}
                                onClick={onDelete}
                                sx={{ 
                                    minWidth: '120px', 
                                    fontWeight: 'bold', 
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                {t('galleryMediaTab_delete')}
                            </Button>
                        </Box>
                    </Box>
                </motion.div>
            </Modal>
        </>
    );
};

export default ScheduleDialog;

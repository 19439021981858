import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import { TabImageButton } from "../conponents/TabImageButton";
import DynamicExpandableSection from "../conponents/expandable/ExpandableSection";
import CustomerSupportButton from "../conponents/CustomerSupportButton";
import {
  getActiveCompany,
  getCurrentUser,
  setActiveCompany,
} from "../services/localStorage";
import { CompanyBare, User } from "../models/backend";
import SettingsDialog from "../conponents/SettingsDialog";
import UserProfileDialog from "../conponents/UserProfileDialog";
import AdPlayer from "../conponents/AdPlayer";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export default function MainPage() {
  const [activeComponentCard1, setActiveComponentCard1] = useState<string>("");
  const [activeComponentCard2, setActiveComponentCard2] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [isLimitedExpanded, setIsLimitedExpanded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const userData = getCurrentUser();
    if (userData) {
      setUser(userData);
      if (userData.companies && userData.companies.length > 0)
        setActiveCompany(userData.companies[0]);

      const subscriptionPaid = userData.companies[0].subscriptionPlanId !== 1;
      setHasPaid(subscriptionPaid);
    }
  }, []);

  const activeCompany = getActiveCompany();
  const handleCard1ButtonClick = (componentName: string) => {
    if (activeComponentCard2) {
      setActiveComponentCard2("");
    }

    setActiveComponentCard1((currentComponent) =>
      currentComponent === componentName ? "" : componentName
    );
  };

  const handleCard2ButtonClick = (componentName: string) => {
    if (activeComponentCard1) {
      setActiveComponentCard1("");
    }

    setActiveComponentCard2((currentComponent) =>
      currentComponent === componentName ? "" : componentName
    );
  };

  const handleSettingsClick = () => {
    setSettingsDialogOpen(true);
  };

  const handleSettingsDialogClose = (selectedCompany: CompanyBare | null) => {
    if (selectedCompany) {
      setActiveCompany(selectedCompany);
    }
    setSettingsDialogOpen(false);
  };

  // Animation variants
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const buttonVariants = {
    hover: { scale: 1.05, transition: { duration: 0.2 } },
    tap: { scale: 0.95, transition: { duration: 0.2 } }
  };

  const warningBannerVariants = {
    collapsed: { height: "auto", opacity: 1 },
    expanded: { height: "auto", opacity: 1 }
  };

  return (
    <>
      {activeCompany && <AdPlayer companyId={activeCompany.id} />}

      <Container component="main" sx={{ mt: 2, mb: 2 }}>
        {!hasPaid && (
          <motion.div
            style={{
              backgroundColor: "yellow",
              padding: "10px",
              marginBottom: "20px",
              textAlign: "center",
              cursor: "pointer",
              borderRadius: "8px",
              overflow: "hidden"
            }}
            onClick={() => setIsLimitedExpanded(!isLimitedExpanded)}
            initial="collapsed"
            animate={isLimitedExpanded ? "expanded" : "collapsed"}
            variants={warningBannerVariants}
            whileHover={{ backgroundColor: "#ffeb3b" }}
            transition={{ duration: 0.3 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t("mainPage_limitedFeatures")} 
              <motion.span
                animate={{ rotate: isLimitedExpanded ? 180 : 0 }}
                transition={{ duration: 0.3 }}
                style={{ display: "inline-block", marginLeft: "5px" }}
              >
                ▼
              </motion.span>
            </Typography>

            <motion.div 
              initial={{ opacity: 0, height: 0 }}
              animate={{ 
                opacity: isLimitedExpanded ? 1 : 0,
                height: isLimitedExpanded ? "auto" : 0
              }}
              transition={{ duration: 0.3 }}
              style={{ overflow: "hidden" }}
            >
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <ul style={{ textAlign: "left", display: "inline-block", color: "white", fontWeight: "normal" }}>
                  <li>{t("mainPage_featuresList.photosLimit")}</li>
                  <li>{t("mainPage_featuresList.photoRequestLimit")}</li>
                  <li>{t("mainPage_featuresList.videosLimit")}</li>
                  <li>{t("mainPage_featuresList.adsDisplay")}</li>
                </ul>
              </div>
            </motion.div>
          </motion.div>
        )}
        <motion.div 
          className="card-with-fading-border"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.1 }}
        >
          <Card
            variant="outlined"
            sx={(theme) => ({
              position: "relative",
              border: "4px solid transparent",
              borderRadius: "16px",
              background: "black",
              boxShadow: "0 4px 20px rgba(254, 134, 57, 0.15)",
              transition: "all 0.3s ease"
            })}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      mt: 0,
                      mb: 0,
                      color: "text.secondary",
                    }}
                  >
                    {activeCompany?.name + "'s"}
                  </Typography>

                  {/* MEDIA CARD */}
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      mt: 0,
                      mb: 0.1,
                      fontWeight: "bold",
                    }}
                  >
                    {t("mainPage_media")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 2, color: "white" }}
                  >
                    {t("mainPage_createAds")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <motion.div
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                  >
                    <IconButton
                      onClick={handleSettingsClick}
                      color="primary"
                      aria-label="settings"
                      sx={{ fontSize: "48px" }}
                    >
                      <SettingsIcon sx={{ fontSize: "48px", color: "primary" }} />
                    </IconButton>
                  </motion.div>
                  <motion.div
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                  >
                    <IconButton
                      onClick={() => setIsUserDialogOpen(true)}
                      color="primary"
                      aria-label="user"
                      sx={{ fontSize: "48px" }}
                    >
                      <AccountCircleIcon
                        sx={{ fontSize: "48px", color: "primary" }}
                      />
                    </IconButton>
                  </motion.div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="space-around"
                alignItems="center"
              >
                <TabImageButton
                  title={t("mainPage_uploadPhotos")}
                  imageSource="/assets/images/card1/edit.png"
                  onButtonClick={() => handleCard1ButtonClick("UploadPhotos")}
                  isActive={activeComponentCard1 === "UploadPhotos"}
                />
                <TabImageButton
                  title={t("mainPage_uploadVideos")}
                  imageSource="/assets/images/card1/create.png"
                  onButtonClick={() => handleCard1ButtonClick("UploadVideos")}
                  isActive={activeComponentCard1 === "UploadVideos"}
                />
                <TabImageButton
                  title={t("mainPage_requestMedia")}
                  imageSource="/assets/images/card1/videos.png"
                  onButtonClick={() =>
                    handleCard1ButtonClick("RequestImageVideo")
                  }
                  isActive={activeComponentCard1 === "RequestImageVideo"}
                />
              </Grid>
            </CardContent>
            <DynamicExpandableSection
              user={user}
              activeComponent={activeComponentCard1}
            />
          </Card>
        </motion.div>

        <motion.div 
          className="card-with-fading-border"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.3 }}
        >
          <Card
            variant="outlined"
            sx={(theme) => ({
              borderRadius: "16px",
              position: "relative",
              background: "black",
              boxShadow: "0 4px 20px rgba(254, 134, 57, 0.15)",
              transition: "all 0.3s ease"
            })}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      mt: 0,
                      mb: 0,
                      color: "text.secondary",
                    }}
                  >
                    {activeCompany?.name + "'s"}
                  </Typography>

                  {/* STUDIO CARD */}
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 0, mb: 4, fontWeight: "bold" }}
                  >
                    {t("mainPage_studio")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="space-around"
                alignItems="center"
              >
                <TabImageButton
                  title={t("mainPage_gallery")}
                  imageSource="/assets/images/card2/gallery.png"
                  onButtonClick={() => handleCard2ButtonClick("Gallery")}
                  isActive={activeComponentCard2 === "Gallery"}
                />
                <TabImageButton
                  title={t("mainPage_statistics")}
                  imageSource="/assets/images/card2/statistics.png"
                  onButtonClick={() => handleCard2ButtonClick("Statistics")}
                  isActive={activeComponentCard2 === "Statistics"}
                  disabled={true}
                />
                <TabImageButton
                  title={t("mainPage_socialMedia")}
                  imageSource="/assets/images/card2/socials.png"
                  onButtonClick={() => handleCard2ButtonClick("SocialMedia")}
                  isActive={activeComponentCard2 === "SocialMedia"}
                  disabled={true}
                />
              </Grid>
            </CardContent>
            <DynamicExpandableSection
              user={user}
              activeComponent={activeComponentCard2}
            />
          </Card>
        </motion.div>

        {/* Customer Support Button */}
        <CustomerSupportButton />

        <Container sx={{ mt: 10 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <motion.img
                src="/assets/images/common/grandOpening.png"
                alt="Grand Opening"
                style={{ maxWidth: "100%", height: "auto" }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
              />
            </Grid>
          </Grid>
        </Container>

        <SettingsDialog
          open={settingsDialogOpen}
          user={user || undefined}
          onClose={handleSettingsDialogClose}
        />
        <UserProfileDialog
          open={isUserDialogOpen}
          user={user}
          onClose={() => setIsUserDialogOpen(false)}
        />
      </Container>
    </>
  );
}

import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { isAdmin } from '../services/authentication';
import { Box } from '@mui/material';
import AdminDrawer from '../conponents/AdminDrawer';
import { motion, AnimatePresence } from 'framer-motion';

const Layout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ minHeight: '100vh', position: 'relative' }}>
        <AnimatePresence>
          {isAdmin() && (
            <AdminDrawer open={drawerOpen} onOpen={handleDrawerOpen} onClose={handleDrawerClose} />
          )}
        </AnimatePresence>
        <Outlet />
      </Box>
    </motion.div>
  );
};

export default Layout;

import React, { useEffect, useState, useRef } from 'react';
import { 
  Button, 
  Card, 
  CardContent, 
  Grid, 
  Typography, 
  Box, 
  useMediaQuery, 
  useTheme,
  Chip,
  Fade,
  Grow,
  IconButton,
  Container
} from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import axios from 'axios';
import { User } from '../models/backend';
import { SubscriptionPlan } from '../models/subscriptionPlans';
import { convertSubscriptionPriceToCents } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import { getTranslatedSubscriptionPlans } from '../services/localStorage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const API_URL = process.env.REACT_APP_API_URL;

interface SubscriptionCarouselProps {
  user: User;
}

interface SubscriptionCardProps {
  plan: SubscriptionPlan;
  handlePayment: () => Promise<void>;
  isSelected: boolean;
  onSelect: () => void;
  isMobile: boolean;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ 
  plan, 
  handlePayment, 
  isSelected,
  onSelect,
  isMobile
}) => {
  const { t } = useTranslation();

  return (
    <Grow in={true} timeout={500}>
      <Card
        variant="outlined"
        onClick={onSelect}
        sx={{
          border: isSelected ? '2px solid' : '1px solid',
          borderColor: isSelected ? 'primary.main' : 'rgba(255, 255, 255, 0.12)',
          borderRadius: '12px',
          background: "rgba(0, 0, 0, 0.7)",
          width: isMobile ? '85vw' : { xs: '240px', sm: '280px', md: '300px' },
          minWidth: isMobile ? '250px' : 'auto',
          maxWidth: isMobile ? '320px' : 'none',
          height: isMobile ? 'auto' : { sm: '280px', md: '300px' },
          mx: 'auto',
          my: isMobile ? 2 : 1,
          p: { xs: 1, sm: 1.5 },
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          transform: isSelected ? 'scale(1.05)' : 'scale(1)',
          boxShadow: isSelected ? '0 8px 16px rgba(0, 0, 0, 0.5)' : 'none',
          position: 'relative',
          overflow: 'visible',
          '&:hover': {
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
            borderColor: 'primary.main',
          },
          flexShrink: 0
        }}
      >
        {plan.discount && (
          <Chip
            label={plan.discount}
            color="primary"
            size="small"
            sx={{
              position: 'absolute',
              top: -10,
              right: 16,
              fontWeight: 'bold',
              fontSize: '0.7rem',
            }}
          />
        )}
        
        <CardContent sx={{ 
          p: isMobile ? 2 : 1, 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column' 
        }}>
          <Box sx={{ mb: 2 }}>
            <Typography 
              variant="h6" 
              component="h2" 
              gutterBottom 
              sx={{ 
                textAlign: 'left', 
                color: isSelected ? 'primary.main' : 'text.secondary',
                fontWeight: isSelected ? 'bold' : 'normal',
                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
              }}
            >
              {plan.title}
              {isSelected && <CheckCircleIcon sx={{ ml: 1, fontSize: '1rem', verticalAlign: 'middle' }} />}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                textAlign: 'left', 
                color: 'white', 
                fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.875rem' },
                mb: 2,
                height: isMobile ? 'auto' : { xs: '60px', sm: '80px' },
                maxHeight: isMobile ? '80px' : 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {plan.description}
            </Typography>
          </Box>
          
          <Box sx={{ mt: 'auto' }}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography 
                  variant="h4" 
                  component="h2"
                  sx={{ 
                    fontWeight: 'bold',
                    fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                    color: isSelected ? 'primary.main' : 'white'
                  }}
                >
                  {plan.price} €
                </Typography>
                <Typography 
                  variant="caption"
                  sx={{ 
                    color: 'grey.400',
                    fontSize: { xs: '0.7rem', sm: '0.75rem' }
                  }}
                >
                  {plan.duration}
                </Typography>
              </Grid>
              
              <Grid item xs={12} sx={{ mt: 2 }}>
                {isMobile ? (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePayment();
                    }}
                    sx={{ 
                      height: { xs: '36px', sm: '40px' }, 
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                      }
                    }}
                  >
                    {t('subscriptionCard_getStarted')}
                  </Button>
                ) : (
                  <Fade in={isSelected} timeout={300}>
                    <Box sx={{ opacity: isSelected ? 1 : 0 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePayment();
                        }}
                        sx={{ 
                          height: { xs: '36px', sm: '40px' }, 
                          borderRadius: '8px',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                          '&:hover': {
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                          }
                        }}
                      >
                        {t('subscriptionCard_getStarted')}
                      </Button>
                    </Box>
                  </Fade>
                )}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grow>
  );
};

const SubscriptionCarousel: React.FC<SubscriptionCarouselProps> = ({ user }) => {
  const { t } = useTranslation();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  
  // Filter out free plan - moved up before it's used
  const filteredPlans = plans.filter(plan => plan.id !== 1);

  useEffect(() => {
    const translatedPlans = getTranslatedSubscriptionPlans();
    setPlans(translatedPlans);
    // Select the first plan by default
    if (translatedPlans.length > 0) {
      setSelectedPlanIndex(0);
    }
  }, []);

  // Add scroll event listener to update currentSlide when manually scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current && isMobile) {
        const container = scrollContainerRef.current;
        const scrollLeft = container.scrollLeft;
        const cardWidth = 250; // Fixed card width for mobile
        const cardGap = 8; // Gap between cards
        
        // Calculate the current index based on scroll position
        const newIndex = Math.round(scrollLeft / (cardWidth + cardGap));
        
        if (newIndex !== currentSlide && newIndex >= 0 && newIndex < filteredPlans.length) {
          setCurrentSlide(newIndex);
          setSelectedPlanIndex(newIndex);
        }
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentSlide, isMobile, filteredPlans.length]);

  const handlePayment = async (plan: SubscriptionPlan) => {
    const endpoint = plan.type === 'subscription' ? '/payment/subscription' : '/payment/onetime';
    try {
      const response = await axios.post(`${API_URL}${endpoint}`, {
        email: user.email,
        companyId: user.companies[0].id,
        lineItems: [{
          price_data: {
            product: plan.productId,
            currency: 'EUR',
            unit_amount: convertSubscriptionPriceToCents(plan.price),
          },
          quantity: 1
        }]
      });
      window.location.href = response.data.url;
    } catch (error: any) {
      console.error(`${t('subscriptionCard_errorProcessingPayment')}: ${error.message}`);
    }
  };

  const handleSelectPlan = (index: number) => {
    setSelectedPlanIndex(index);
    setCurrentSlide(index);
    
    // Scroll to the selected card on mobile
    if (isMobile && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const cardWidth = 250; // Fixed card width for mobile (minWidth from Card sx)
      const cardGap = 8; // Gap between cards (1 in sx)
      
      // Calculate the scroll position to center the card
      const scrollPosition = (cardWidth + cardGap) * index;
      
      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  };

  // Handle navigation for both mobile and desktop
  const navigateNext = () => {
    if (currentSlide < filteredPlans.length - 1) {
      const newIndex = currentSlide + 1;
      handleSelectPlan(newIndex);
    }
  };

  const navigatePrev = () => {
    if (currentSlide > 0) {
      const newIndex = currentSlide - 1;
      handleSelectPlan(newIndex);
    }
  };

  // Render mobile carousel
  const renderMobileCarousel = () => {
    return (
      <>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1,
          gap: 2
        }}>
          <IconButton 
            onClick={navigatePrev}
            disabled={currentSlide === 0}
            sx={{ 
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.3)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
              '&.Mui-disabled': { color: 'rgba(255,255,255,0.3)' }
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: 'white' }}>
            {currentSlide + 1} / {filteredPlans.length}
          </Typography>
          <IconButton 
            onClick={navigateNext}
            disabled={currentSlide === filteredPlans.length - 1}
            sx={{ 
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.3)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
              '&.Mui-disabled': { color: 'rgba(255,255,255,0.3)' }
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
        
        <Box 
          ref={scrollContainerRef}
          sx={{ 
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: 1,
            overflowX: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            pb: 2,
            px: 0,
            scrollSnapType: 'x mandatory',
            scrollBehavior: 'smooth',
            msOverflowStyle: 'none',
            width: '100%'
          }}
        >
          <Box sx={{ minWidth: 'calc(50% - 125px)', height: '1px', flexShrink: 0 }} />
          
          {filteredPlans.map((plan, index) => (
            <Box 
              key={index}
              sx={{ 
                scrollSnapAlign: 'center',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexShrink: 0
              }}
            >
              <SubscriptionCard 
                plan={plan} 
                handlePayment={() => handlePayment(plan)} 
                isSelected={selectedPlanIndex === index}
                onSelect={() => handleSelectPlan(index)}
                isMobile={true}
              />
            </Box>
          ))}
          
          <Box sx={{ minWidth: 'calc(50% - 125px)', height: '1px', flexShrink: 0 }} />
        </Box>
      </>
    );
  };

  // Render desktop carousel - simplified to match mobile approach
  const renderDesktopCarousel = () => {
    return (
      <>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          mb: 3,
          gap: 3
        }}>
          <IconButton 
            onClick={navigatePrev}
            disabled={currentSlide === 0}
            sx={{ 
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
              '&.Mui-disabled': { color: 'rgba(255,255,255,0.3)' },
              width: 48,
              height: 48
            }}
          >
            <NavigateBeforeIcon fontSize="medium" />
          </IconButton>
          <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', minWidth: '80px', textAlign: 'center' }}>
            {currentSlide + 1} / {filteredPlans.length}
          </Typography>
          <IconButton 
            onClick={navigateNext}
            disabled={currentSlide === filteredPlans.length - 1}
            sx={{ 
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
              '&.Mui-disabled': { color: 'rgba(255,255,255,0.3)' },
              width: 48,
              height: 48
            }}
          >
            <NavigateNextIcon fontSize="medium" />
          </IconButton>
        </Box>
        
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: { sm: '320px', md: '350px' }
        }}>
          {filteredPlans.length > 0 ? (
            <SubscriptionCard 
              plan={filteredPlans[currentSlide]} 
              handlePayment={() => handlePayment(filteredPlans[currentSlide])} 
              isSelected={true}
              onSelect={() => {}}
              isMobile={false}
            />
          ) : (
            <Typography variant="body1" sx={{ color: 'white' }}>
              {t('subscriptionCard_noPlansAvailable') || 'No subscription plans available'}
            </Typography>
          )}
        </Box>
      </>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ 
      position: 'relative', 
      px: { xs: 0, sm: 2, md: 4 },
      overflow: 'hidden'
    }}>
      <Typography 
        variant="h5" 
        component="h2" 
        sx={{ 
          textAlign: 'center', 
          mb: 3, 
          color: 'white',
          fontWeight: 'bold',
          fontSize: isMobile ? '1.25rem' : '1.5rem',
          px: 2
        }}
      >
        {t('subscriptionCard_choosePlan') || 'Choose Your Plan'}
      </Typography>
      
      {isMobile ? renderMobileCarousel() : renderDesktopCarousel()}
    </Container>
  );
};

export default SubscriptionCarousel;

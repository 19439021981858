import React from 'react';
import { MediaItem } from '../../../models/media';
import MediaTabComponent from './MediaTab';
import { motion } from 'framer-motion';

export interface MediaComponentProps {
    data: MediaItem[];
    refreshImages: () => void;
    tvId: number;
}

const ImagesTabComponent: React.FC<MediaComponentProps> = ({ data, refreshImages, tvId }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
        >
            <MediaTabComponent data={data} refreshMedia={refreshImages} tvId={tvId} />
        </motion.div>
    );
};

export default ImagesTabComponent;

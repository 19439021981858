import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress,
    Backdrop,
    Card,
    CardContent,
} from '@mui/material';
import axios from 'axios';
import theme from '../../helpers/theme';
import { getToken } from '../../services/authentication';
import { useTranslation } from 'react-i18next';
import { getTvLimit } from '../../helpers/common';
import { getActiveCompany } from '../../services/localStorage';
import { motion } from 'framer-motion';

const API_URL = process.env.REACT_APP_API_URL;

const UploadVideosComponent: React.FC = () => {
    const [videoTitle, setVideoTitle] = useState('');
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTv, setSelectedTv] = useState<number>(1);
    const [tvCount, setTvCount] = useState<number>(0);
    const [tvIds, setTvIds] = useState<number[]>([]);

    const { t } = useTranslation();

    const formClear = () => {
        setVideoTitle('');
        setVideoFile(null);
        setVideoPreview(null);
    };

    useEffect(() => {
        const fetchTvs = async () => {
            const company = getActiveCompany();
            if (company) {
                const customLimits = company.customLimits;
                const subscriptionPlanId = company.subscriptionPlanId;
                const limit = getTvLimit(subscriptionPlanId, customLimits);
                setTvCount(limit);

                const tvs = company.tvs || [];
                // Sort TV IDs to ensure they are in correct order (same as in UploadPhotosComponent)
                const sortedTvs = tvs.sort((a, b) => a.id - b.id);
                setTvIds(sortedTvs.map((tv) => tv.id)); // Correctly map the sorted IDs
            }
        };

        fetchTvs();
    }, []);

    const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setVideoFile(file);

        if (file) {
            const url = URL.createObjectURL(file);
            setVideoPreview(url);
        } else {
            setVideoPreview(null);
        }
    };

    const handleUpload = async () => {
        if (!videoFile) {
            alert('Please fill in all fields and select a video.');
            return;
        }
        const company = getActiveCompany();
        if (!company) return;
        setLoading(true);
        const companyId = company.id;
        const formData = new FormData();
        formData.append('companyId', companyId.toString());
        formData.append('title', videoTitle);
        formData.append('tvId', tvIds[selectedTv - 1].toString()); // Use the correctly mapped TV ID
        formData.append('media', videoFile);

        try {
            const token = getToken();
            const response = await axios.post(API_URL + '/company/uploadMedia', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            const firstUploadResult = response.data.data[0]; // Get the first upload result

            if (firstUploadResult && firstUploadResult.Location) {
                setSnackbarMessage(t('uploadVideosComponent_uploadSuccess'));
                setUploadSuccess(true);
            } else {
                setSnackbarMessage(t('uploadVideosComponent_uploadError'));
                setUploadSuccess(false);
            }

            setLoading(false);
            formClear();
            setSnackbarOpen(true);
        } catch (error: any) {
            let errorMessage = 'Error uploading video';
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            setSnackbarMessage(errorMessage);
            setUploadSuccess(false);
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <Container sx={{ mt: 4, mb: 4 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => { }}
            >
                <CircularProgress size={64} color="inherit" />
            </Backdrop>
            
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Grid container spacing={3} justifyContent="center">
                    {/* Left Column - Upload Video Section */}
                    <Grid item xs={12} md={5}>
                        <Card 
                            variant="outlined" 
                            sx={{ 
                                height: '100%',
                                backgroundColor: 'background.default',
                                borderColor: 'rgba(255, 255, 255, 0.12)',
                                borderRadius: '12px',
                                overflow: 'hidden',
                                maxWidth: '450px',
                                mx: 'auto'
                            }}
                        >
                            <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6" sx={{ color: 'white', mb: 2, fontWeight: 'bold' }}>
                                    {t('uploadVideosComponent_uploadVideo')}
                                </Typography>
                                
                                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <label htmlFor="upload-video-input">
                                        <input
                                            accept="video/*"
                                            id="upload-video-input"
                                            type="file"
                                            hidden
                                            onChange={handleVideoChange}
                                        />
                                        <motion.div
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.98 }}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: window.innerWidth <= 600 ? "85%" : "90%",
                                                margin: "0 auto",
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                border: videoPreview ? 'none' : '2px dashed rgba(255, 255, 255, 0.3)',
                                                padding: videoPreview ? 0 : window.innerWidth <= 600 ? "16px 16px 40px" : "16px 16px 32px",
                                                minHeight: window.innerWidth <= 600 ? '150px' : '220px',
                                                backgroundColor: videoPreview ? 'transparent' : 'rgba(255, 255, 255, 0.05)',
                                                marginBottom: '16px'
                                            }}
                                        >
                                            {videoPreview ? (
                                                <video 
                                                    width="100%" 
                                                    height="auto" 
                                                    controls 
                                                    style={{ 
                                                        maxHeight: window.innerWidth <= 600 ? '150px' : '220px',
                                                        borderRadius: '8px'
                                                    }}
                                                >
                                                    <source src={videoPreview} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <img
                                                        src="/assets/images/card1/uploadVideo.png"
                                                        alt="Upload"
                                                        style={{
                                                            width: 'auto',
                                                            height: window.innerWidth <= 600 ? "120px" : "160px",
                                                            marginBottom: window.innerWidth <= 600 ? '8px' : '12px',
                                                            filter: `invert(56%) sepia(94%) saturate(2400%) hue-rotate(-10deg) brightness(102%) contrast(102%)`,
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </motion.div>
                                    </label>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Right Column - Settings Section */}
                    <Grid item xs={12} md={5}>
                        <Card 
                            variant="outlined" 
                            sx={{ 
                                height: '100%',
                                backgroundColor: 'background.default',
                                borderColor: 'rgba(255, 255, 255, 0.12)',
                                borderRadius: '12px',
                                maxWidth: '450px',
                                mx: 'auto'
                            }}
                        >
                            <CardContent sx={{ p: 3 }}>
                                <Typography variant="h6" sx={{ color: 'white', mb: 3, fontWeight: 'bold' }}>
                                    {t('uploadVideosComponent_settings')}
                                </Typography>

                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.disabled', mb: 1 }}>
                                        {t('uploadVideosComponent_videoTitle')}
                                    </Typography>
                                    <TextField
                                        placeholder={t('uploadVideosComponent_enterTitle')}
                                        fullWidth
                                        variant="outlined"
                                        value={videoTitle}
                                        onChange={(e) => setVideoTitle(e.target.value)}
                                        sx={{
                                            backgroundColor: '#3D3D3D',
                                            '& .MuiOutlinedInput-input': {
                                                color: 'white',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'white',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main',
                                                },
                                            },
                                        }}
                                    />
                                </Box>

                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.disabled', mb: 1 }}>
                                        {t('uploadVideosComponent_selectTv')}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                        {Array.from({ length: tvCount }, (_, index) => (
                                            <motion.div
                                                key={index}
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                <Button
                                                    variant={selectedTv === index + 1 ? 'contained' : 'outlined'}
                                                    color="primary"
                                                    onClick={() => setSelectedTv(index + 1)}
                                                    sx={{
                                                        minWidth: '40px',
                                                        height: '40px',
                                                        backgroundColor:
                                                            selectedTv === index + 1 ? theme.palette.primary.main : '#3D3D3D',
                                                        borderColor: 'white',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: selectedTv === index + 1 ? theme.palette.primary.dark : '#5a5a5a',
                                                        },
                                                    }}
                                                >
                                                    {index + 1}
                                                </Button>
                                            </motion.div>
                                        ))}
                                    </Box>
                                </Box>

                                {/* Upload Button */}
                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <motion.div
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        style={{ width: '80%', maxWidth: '300px', overflow: 'hidden' }}
                                    >
                                        <Button
                                            onClick={handleUpload}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{ 
                                                mt: 2, 
                                                height: '50px', 
                                                borderRadius: '8px',
                                                fontWeight: 'bold',
                                                boxShadow: '0 4px 10px rgba(254, 134, 57, 0.3)'
                                            }}
                                        >
                                            {t('uploadVideosComponent_upload')}
                                        </Button>
                                    </motion.div>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </motion.div>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={uploadSuccess ? 'success' : 'error'}
                    sx={{ backgroundColor: 'black', color: 'primary.main', border: `1px solid ${theme.palette.primary.main}` }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UploadVideosComponent;

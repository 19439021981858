import React, { useState, useEffect, useCallback } from 'react';
import { Box, Paper, TextField, Button, Typography, Container, Alert, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { login, isAuthenticated } from '../services/authentication';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { LoginResponse } from '../models/backend';

const ConnectPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginError, setLoginError] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    // Parse callback URL from query parameters
    const urlParams = new URLSearchParams(location.search);
    const callback = urlParams.get('callback');

    const handleRedirectToCallback = useCallback(() => {
        if (callback) {
            setRedirecting(true);
            
            try {
                // Get login data directly from localStorage
                const loginDataStr = localStorage.getItem("wistaa_data");
                
                if (loginDataStr) {
                    const loginData: LoginResponse = JSON.parse(loginDataStr);
                    const userToken = loginData.token;
                    
                    // Get the first company from the user's companies array
                    const companyId = loginData.user.companies.length > 0 
                        ? loginData.user.companies[0].id 
                        : null;
                    
                    // Get TV IDs from the first company
                    const tvIds = loginData.user.companies.length > 0 && loginData.user.companies[0].tvs
                        ? loginData.user.companies[0].tvs.map(tv => tv.id)
                        : [];
                    
                    if (companyId !== null) {
                        // Construct redirect URL with token, companyId and tvIds
                        const redirectUrl = `${decodeURIComponent(callback)}?token=${userToken}&companyId=${companyId}&tvIds=${tvIds.join(',')}`;
                        
                        // Redirect to the callback URL
                        window.location.href = redirectUrl;
                    } else {
                        setLoginError("No company found for this user");
                        setRedirecting(false);
                    }
                } else {
                    setLoginError("No login data found");
                    setRedirecting(false);
                }
            } catch (error) {
                console.error("Error during redirect:", error);
                setLoginError("Error processing login data");
                setRedirecting(false);
            }
        } else {
            // If no callback is provided, redirect to dashboard
            navigate('/');
        }
    }, [callback, navigate]);

    useEffect(() => {
        // Check if user is already authenticated
        if (isAuthenticated()) {
            handleRedirectToCallback();
        }
    }, [handleRedirectToCallback]);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginError('');
        disableUI(true);

        try {
            const [loginSuccess, errorMessage] = await login(email, password);
            if (loginSuccess) {
                handleRedirectToCallback();
            } else {
                setLoginError(errorMessage);
            }
        } catch (error: any) {
            setLoginError(error.response?.data?.error || t('loginPage_errorValidEmail'));
        } finally {
            disableUI(false);
        }
    };

    const disableUI = (disableUI: boolean) => {
        setIsDisabled(disableUI);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <Paper
                elevation={6}
                sx={{
                    padding: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '500px',
                    width: '100%',
                }}
            >
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                    {t('Connect to Wistaa')}
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
                    {t("You're connecting your Wistaa account to a restaurant app. After logging in, you'll be redirected back automatically.")}
                </Typography>

                {redirecting ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
                        <CircularProgress />
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {t('Redirecting to restaurant app...')}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        {loginError && (
                            <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
                                {loginError}
                            </Alert>
                        )}

                        <Box component="form" onSubmit={handleLogin} sx={{ width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('loginPage_emailLabel')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={isDisabled}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('loginPage_passwordLabel')}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={isDisabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={isDisabled}
                            >
                                {isDisabled ? <CircularProgress size={24} /> : t('Connect')}
                            </Button>
                        </Box>
                    </>
                )}
            </Paper>
        </Container>
    );
};

export default ConnectPage; 
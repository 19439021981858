import React, { useState } from 'react';
import { 
  Dialog, 
  DialogContent,
  FormControl, 
  Select, 
  MenuItem, 
  Typography, 
  Box,
  IconButton,
  useMediaQuery,
  Slide,
  Paper,
  Avatar,
  useTheme,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyBare, User } from '../models/backend';
import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import { TransitionProps } from '@mui/material/transitions';

// Slide transition for dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SettingsDialogProps {
  open: boolean;
  user: User | undefined;
  onClose: (selectedCompany: CompanyBare | null) => void;
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({ open, user, onClose }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleClose = () => {
    onClose(null);
  };

  const handleChangeLanguage = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={isMobile ? 'xs' : 'sm'}
      fullScreen={isMobile}
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          border: isMobile ? 'none' : 'thick solid',
          borderColor: 'primary.main',
          borderRadius: isMobile ? '0px' : '16px',
          backgroundColor: 'black',
          overflow: 'hidden',
          m: isMobile ? 0 : 2,
          height: isMobile ? '100%' : 'auto',
          maxHeight: isMobile ? '100%' : '80vh'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: isMobile ? 1.5 : 2,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          sx={{ 
            color: 'white', 
            fontWeight: 'bold',
            fontSize: isMobile ? '1.1rem' : '1.25rem'
          }}
        >
          {t('settingsDialog_title') || 'Settings'}
        </Typography>
        <IconButton 
          edge="end" 
          color="inherit" 
          onClick={handleClose} 
          aria-label="close"
          size="small"
          sx={{ 
            color: 'white',
            padding: 0,
            width: '32px',
            height: '32px',
            minWidth: '32px',
            minHeight: '32px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <DialogContent sx={{ 
        p: 0, 
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '4px'
        }
      }}>
        <Paper 
          elevation={0} 
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.05)', 
            m: isMobile ? 1 : 2, 
            p: isMobile ? 1.5 : 2, 
            borderRadius: '12px',
            transition: 'all 0.3s ease'
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 1,
            flexDirection: isMobile ? 'column' : 'row',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <Avatar sx={{ 
              bgcolor: 'primary.main', 
              mr: isMobile ? 0 : 2, 
              mb: isMobile ? 1 : 0,
              width: isMobile ? 40 : 32, 
              height: isMobile ? 40 : 32, 
              fontSize: isMobile ? '16px' : '14px' 
            }}>
              {user?.digitPin?.toString().charAt(0) || '?'}
            </Avatar>
            <Box>
              <Typography 
                variant={isMobile ? "body2" : "body1"} 
                sx={{ 
                  color: 'white',
                  fontSize: isMobile ? '0.9rem' : '1rem'
                }}
              >
                {t('settingsDialog_yourPin')}: <strong>{user?.digitPin}</strong>
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'white', 
                  mt: 0.5,
                  fontSize: isMobile ? '0.8rem' : '0.875rem'
                }}
              >
                {t('settingsDialog_yourRegion')}: <strong>{user?.companies[0].regionId}</strong>
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Paper 
          elevation={0} 
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.05)', 
            m: isMobile ? 1 : 2, 
            p: isMobile ? 1.5 : 2, 
            borderRadius: '12px',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 1.5 : 0
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              mb: isMobile ? 1 : 0
            }}>
              <LanguageIcon sx={{ 
                color: 'primary.main', 
                mr: 1.5,
                fontSize: isMobile ? '1.2rem' : '1.5rem'
              }} />
              <Typography 
                variant={isMobile ? "body2" : "body1"} 
                sx={{ 
                  color: 'white',
                  fontSize: isMobile ? '0.9rem' : '1rem'
                }}
              >
                {t('settingsDialog_language') || 'Language'}
              </Typography>
            </Box>
            <FormControl 
              variant="outlined" 
              size={isMobile ? "small" : "medium"} 
              sx={{ 
                minWidth: isMobile ? '100%' : 120
              }}
            >
              <Select
                id="language-select"
                value={selectedLanguage}
                onChange={(e) => handleChangeLanguage(e.target.value as string)}
                displayEmpty
                sx={{
                  backgroundColor: 'rgba(61, 61, 61, 0.8)',
                  '& .MuiSelect-select': {
                    color: 'white',
                    py: isMobile ? 0.75 : 1,
                    fontSize: isMobile ? '0.85rem' : '1rem'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  },
                  borderRadius: '8px',
                  transition: 'all 0.2s ease'
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: '#2D2D2D',
                      color: 'white',
                      borderRadius: '8px',
                      mt: 1
                    }
                  }
                }}
              >
                <MenuItem value="en-US">English</MenuItem>
                <MenuItem value="si">Slovenian</MenuItem>
                <MenuItem value="it">Italian</MenuItem>
                <MenuItem value="de">German</MenuItem>
                <MenuItem value="hr">Croatian</MenuItem>
                <MenuItem value="mk">Macedonian</MenuItem>
                <MenuItem value="sr">Serbian</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>
        
        {isMobile && (
          <Box sx={{ 
            width: '100%', 
            p: 2, 
            mt: 2,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{
                width: 'auto',
                minWidth: '120px',
                maxWidth: '200px',
                py: 1,
                px: 3,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 'bold'
              }}
            >
              {t('settingsDialog_close') || 'Close'}
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;

import { Grid, IconButton, Typography } from "@mui/material";
import theme from "../helpers/theme";
import { motion } from "framer-motion";

interface TabImageButtonProps {
  title: string;
  imageSource: string;
  onButtonClick: () => void;
  isActive: boolean;
  disabled?: boolean; // Add disabled prop
}

export function TabImageButton({
  imageSource,
  title,
  onButtonClick,
  isActive,
  disabled = false,
}: TabImageButtonProps) {
  return (
    <Grid item xs={4} style={{ textAlign: "center" }}>
      <motion.div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          cursor: disabled ? "not-allowed" : "pointer", // Change cursor if disabled
          opacity: disabled ? 0.5 : 1, // Reduce opacity if disabled
        }}
        onClick={!disabled ? onButtonClick : undefined} // Disable onClick if disabled
        whileHover={{ scale: disabled ? 1 : 1.05 }}
        whileTap={{ scale: disabled ? 1 : 0.95 }}
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
      >
        <motion.div
          animate={{ 
            rotate: isActive ? [0, 10, -10, 0] : 0,
            scale: isActive ? [1, 1.1, 1] : 1
          }}
          transition={{ 
            duration: 0.5,
            ease: "easeInOut"
          }}
        >
          <IconButton
            color="primary"
            aria-label={title.toLowerCase().split(" ")[0]}
            sx={{
              p: 0,
              borderRadius: "10px",
              width: theme.spacing(9),
              height: theme.spacing(9),
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isActive
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              marginBottom: "8px",
              "&:hover": {
                backgroundColor: isActive
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
              },
              transition: "background-color 0.3s ease",
            }}
          >
            <img
              src={imageSource}
              alt={title}
              style={{ width: "60%", height: "60%" }}
            />
          </IconButton>
        </motion.div>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
          <span
            style={{
              color: isActive
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            }}
          >
            {title.split(" ")[0]}
          </span>{" "}
          {title.split(" ")[1]}
        </Typography>
      </motion.div>
    </Grid>
  );
}
